import "./style.css";
import * as THREE from "three";

{
	console.log(
		`%cHi, welcome to my website!`,
		"background: #150f1e; color: #6252ea; font-weight: bold; border-radius: 0.5rem; padding: 7px 15px 7px 15px;"
	);
	console.log(
		`%cSearch for the hidden * meow * for a little surprise :)`,
		"background: #150f1e; color: #f062a4; font-weight: bold; margin: 0; border-radius: 0.5rem; padding: 7px 15px 7px 15px;"
	);
	console.log(
		`%cEnjoy!`,
		"background: #150f1e; color: #fbb54b; font-weight: bold; border-radius: 0.5rem; padding: 7px 15px 7px 15px;"
	);

	const aiImageObject = [
		{
			id: 1,
			src: "bug-01.jpg",
			alt: "AI bug",
		},
		{
			id: 2,
			src: "birdman.jpg",
			alt: "AI birdman",
		},
		{
			id: 3,
			src: "easter-chicken.jpg",
			alt: "AI easter chicken",
		},
		{
			id: 4,
			src: "ash-01.jpg",
			alt: "AI ash",
		},
		{
			id: 5,
			src: "luke-skywalker-01.jpg",
			alt: "AI luke skywalker",
		},
		{
			id: 6,
			src: "grass-creature.jpg",
			alt: "AI creature",
		},
		{
			id: 7,
			src: "warrior-01.jpg",
			alt: "AI warrior",
		},
		{
			id: 8,
			src: "ninja-01.jpg",
			alt: "AI ninja",
		},
		{
			id: 9,
			src: "character-01.jpg",
			alt: "AI character",
		},
	];

	const getLetters = () => {
		const bigLetters = document.querySelectorAll(`.apart-l`);
		const bigLettersArray = Array.from(bigLetters);
		const smallLetters = document.querySelectorAll(`.apart-l-small`);
		const smallLettersArray = Array.from(smallLetters);

		smallLettersArray.forEach((letter) => {
			letter.classList.add(`transparant`);
		});

		let activeLetter;

		bigLettersArray.forEach((letter) => {
			letter.addEventListener("mouseenter", (e) => {
				const randomRotationNumber = Math.floor(Math.random() * 20) - 10;

				//console.log(bigLettersArray.indexOf(letter));
				activeLetter = bigLettersArray.indexOf(letter);
				// console.log(activeLetter);
				if (activeLetter !== 4) {
					smallLettersArray[activeLetter].classList.remove(`animateLettersOut`);
					smallLettersArray[activeLetter].classList.remove(`transparant`);
					smallLettersArray[activeLetter].classList.add(`animateLettersIn`);
				}
				letter.classList.remove(`animateLettersOutScale`);
				letter.classList.add(`animateLettersInScale`);

				letter.style.transform = `rotate(${randomRotationNumber}deg)`;
			});
			letter.addEventListener("mouseleave", (e) => {
				smallLettersArray[activeLetter].classList.add(`animateLettersOut`);
				smallLettersArray[activeLetter].classList.remove(`animateLettersIn`);
				letter.style.pointerEvents = `none`;

				setTimeout(function () {
					letter.classList.remove(`animateLettersInScale`);
					letter.classList.add(`animateLettersOutScale`);

					letter.style.transform = "rotate(0deg)";
				}, 800);

				setTimeout(function () {
					letter.style.pointerEvents = `inherit`;
				}, 1200);
			});
		});
	};

	const clickProjectScrolls = () => {
		const scrollDevelop = document.querySelector(`.scroll-develop`);
		const leftScrollDevelop = document.querySelector(`.left-scroll-develop`);
		const rightScrollDevelop = document.querySelector(`.right-scroll-develop`);

		const scrollDesign = document.querySelector(`.scroll-design`);
		const leftScrollDesign = document.querySelector(`.left-scroll-design`);
		const rightScrollDesign = document.querySelector(`.right-scroll-design`);

		const scrollAi = document.querySelector(`.scroll-ai`);
		const leftScrollAi = document.querySelector(`.left-scroll-ai`);
		const rightScrollAi = document.querySelector(`.right-scroll-ai`);

		const ua = navigator.userAgent;

		if (
			/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua) ||
			/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				ua
			)
		) {
			//console.log(`tablet`);
			leftScrollDesign.classList.add(`hidden`);
			rightScrollDesign.classList.add(`hidden`);

			leftScrollDevelop.classList.add(`hidden`);
			rightScrollDevelop.classList.add(`hidden`);

			leftScrollAi.classList.add(`hidden`);
			rightScrollAi.classList.add(`hidden`);
		}

		const clickLeftBtnDevelop = () => {
			scrollDevelop.scrollBy(-700, 0);
		};

		const clickRightBtnDevelop = () => {
			scrollDevelop.scrollBy(700, 0);
		};

		const clickLeftBtnDesign = () => {
			scrollDesign.scrollBy(-700, 0);
		};

		const clickRightBtnDesign = () => {
			scrollDesign.scrollBy(700, 0);
		};

		const clickLeftBtnAi = () => {
			scrollAi.scrollBy(-700, 0);
		};

		const clickRightBtnAi = () => {
			scrollAi.scrollBy(700, 0);
		};

		leftScrollDesign.addEventListener("click", clickLeftBtnDesign);
		rightScrollDesign.addEventListener("click", clickRightBtnDesign);

		leftScrollDevelop.addEventListener("click", clickLeftBtnDevelop);
		rightScrollDevelop.addEventListener("click", clickRightBtnDevelop);

		leftScrollAi.addEventListener("click", clickLeftBtnAi);
		rightScrollAi.addEventListener("click", clickRightBtnAi);
	};

	clickProjectScrolls();

	let catKeep = true;

	const catface = () => {
		const meowButton = document.querySelector(`.cat-mode-activation`);
		const getCat = document.querySelector(`.apart-l-cat`);
		const getLetterO = document.querySelector(`.absolute-l`);
		meowButton.addEventListener(`click`, (e) => {
			if (catKeep) {
				getLetterO.classList.remove(`animateLettersOutScale`);
				getCat.classList.remove(`cat-animation-out`);
				getCat.classList.add(`cat-animation-in`);
				getLetterO.classList.remove(`opac-up`);
				getLetterO.classList.add(`opac-down`);
				catKeep = false;
			} else {
				getLetterO.classList.remove(`animateLettersOutScale`);
				getCat.classList.remove(`cat-animation-in`);
				getCat.classList.add(`cat-animation-out`);
				setTimeout(function () {
					getLetterO.classList.remove(`opac-down`);
					getLetterO.classList.add(`opac-up`);
				}, 400);
				catKeep = true;
			}
		});
	};

	catface();

	const customCursor = () => {
		const deviceType = () => {
			const $bigBall = document.querySelector(".cursor__ball--big");
			const $smallBall = document.querySelector(".cursor__ball--small");
			const $hoverables = document.querySelectorAll(".hoverable");
			const $noCursHover = document.querySelectorAll(".no-curs-hover");

			const $sideBols = document.querySelectorAll(".padding-sidebol");

			// Listeners
			document.addEventListener("mousemove", onMouseMove);

			const ua = navigator.userAgent;

			if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
				//console.log(`tablet`);
				$bigBall.classList.add(`hidden`);
				$smallBall.classList.add(`hidden`);
			} else if (
				/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
					ua
				)
			) {
				//console.log(`mobile`);
				$bigBall.classList.add(`hidden`);
				$smallBall.classList.add(`hidden`);
			} else {
				$bigBall.classList.remove(`hidden`);
				$smallBall.classList.remove(`hidden`);
			}

			for (let i = 0; i < $hoverables.length; i++) {
				$hoverables[i].addEventListener("mouseenter", onMouseHover);
				$hoverables[i].addEventListener("mouseleave", onMouseHoverOut);
				//console.log(`hallo?`);
			}

			for (let i = 0; i < $sideBols.length; i++) {
				$sideBols[i].addEventListener("mouseenter", onMouseHoverBols);
				$sideBols[i].addEventListener("mouseleave", onMouseHoverOutBols);
				//console.log(`hallo?`);
			}

			for (let i = 0; i < $noCursHover.length; i++) {
				$noCursHover[i].addEventListener("mouseenter", onMouseHoverNoCurs);
				$noCursHover[i].addEventListener("mouseleave", onMouseHoverOutNoCurs);
				//console.log(`hallo?`);
			}

			// Move the cursor
			function onMouseMove(e) {
				TweenMax.to($bigBall, 0.4, {
					x: e.clientX - 15,
					y: e.clientY - 15,
				});
				TweenMax.to($smallBall, 0.1, {
					x: e.clientX - 5,
					y: e.clientY - 7,
				});
			}

			// Hover an element
			function onMouseHover() {
				TweenMax.to($bigBall, 0.3, {
					scale: 4,
				});
				TweenMax.to($smallBall, 0.3, {
					scale: 0,
				});
			}
			function onMouseHoverOut() {
				TweenMax.to($bigBall, 0.3, {
					scale: 1,
				});
				TweenMax.to($smallBall, 0.3, {
					scale: 1,
				});
			}

			// no cursor hover
			function onMouseHoverNoCurs() {
				TweenMax.to($bigBall, 0.3, {
					scale: 0,
				});
				TweenMax.to($smallBall, 0.3, {
					scale: 0,
				});
			}
			function onMouseHoverOutNoCurs() {
				TweenMax.to($bigBall, 0.3, {
					scale: 1,
				});
				TweenMax.to($smallBall, 0.3, {
					scale: 1,
				});
			}

			// BOLS
			function onMouseHoverBols() {
				TweenMax.to($bigBall, 0.3, {
					scale: 2,
				});
				TweenMax.to($smallBall, 0.3, {
					scale: 0,
				});
			}
			function onMouseHoverOutBols() {
				TweenMax.to($bigBall, 0.3, {
					scale: 1,
				});
				TweenMax.to($smallBall, 0.3, {
					scale: 1,
				});
			}
		};

		deviceType();
	};

	const hoverOnProjects = () => {
		const $allProjectApart = document.querySelectorAll(".project-apart");

		$allProjectApart.forEach((projectApart) => {
			projectApart.addEventListener("mouseenter", () => {
				$allProjectApart.forEach((checkProject) => {
					if (checkProject !== projectApart) {
						checkProject.style.opacity = "0.4";
					}
				});
			});

			projectApart.addEventListener("mouseleave", () => {
				$allProjectApart.forEach((checkProject) => {
					checkProject.style.opacity = "1";
				});
			});
		});
	};

	const createAiImages = () => {
		const scrollbarAI = document.querySelector(`.scrollbar-pr.scroll-ai`);
		console.log(scrollbarAI);

		aiImageObject.forEach((element) => {
			const newAiImg = document.createElement("img");
			scrollbarAI.appendChild(newAiImg);
			newAiImg.setAttribute("src", `assets/images/ai-min/${element.src}`);
			newAiImg.setAttribute("data-src", `assets/images/ai/${element.src}`);
			newAiImg.setAttribute("alt", `${element.alt}`);
			newAiImg.setAttribute("class", "ai-images lazy-ai");
		});
	};

	const lazyLoadBackgrounds = (backgrounds) => {
		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const background = entry.target;
					const bgSrc = background.getAttribute("data-bg");

					if (bgSrc) {
						const img = new Image();
						img.src = bgSrc;
						img.onload = () => {
							background.style.backgroundImage = `url('${bgSrc}')`;
							background.style.opacity = "1";

							background.addEventListener("animationend", () => {
								background.removeAttribute("data-bg");
								background.classList.remove("lazy");
								observer.unobserve(background);
							});
						};
					}
				}
			});
		});

		backgrounds.forEach((background) => {
			observer.observe(background);
		});
	};

	const lazyLoadImagesAi = (images) => {
		const observer = new IntersectionObserver((entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const img = entry.target;
					img.src = img.dataset.src;
					img.removeAttribute("data-src");
					img.classList.remove("lazy-ai");
					observer.unobserve(img);
				}
			});
		});

		images.forEach((image) => {
			observer.observe(image);
		});
	};

	/*-- move eyes cat --*/

	const balls = document.getElementsByClassName("eye-cheek");

	document.onmousemove = function () {
		let x = ((event.clientX * 50) / window.innerWidth) * 1.5 + "%";
		let y = ((event.clientY * 50) / window.innerHeight) * 1.5 + "%";

		for (let i = 0; i < 2; i++) {
			balls[i].style.left = x;
			balls[i].style.top = y;
		}
	};

	const init = () => {
		const isHoverableDevice = window.matchMedia(
			"(hover: hover) and (pointer: fine)"
		);

		getLetters(); // animite letters
		customCursor(); // custom cursor

		if (isHoverableDevice.matches) {
			hoverOnProjects(); // hover on projects
		}

		const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
		const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

		const matcher = window.matchMedia("(prefers-color-scheme: light)");
		matcher.addListener(onUpdate);
		onUpdate();

		function onUpdate() {
			if (!matcher.matches) {
				lightSchemeIcon.remove();
				document.head.append(darkSchemeIcon);
				//console.log(`dark`);
			} else {
				document.head.append(lightSchemeIcon);
				darkSchemeIcon.remove();
				//console.log(`white`);
			}
		}

		// create AI images dynamically
		createAiImages();

		// Get all elements with the 'lazy' class for background images and AI images
		const lazyBackgrounds = document.querySelectorAll(".lazy");
		const lazyImagesAi = document.querySelectorAll(".lazy-ai");

		// Start lazy loading background images and AI images
		lazyLoadBackgrounds(lazyBackgrounds);
		lazyLoadImagesAi(lazyImagesAi);
	};

	init();

	// -------------------------------------------------------------- THREE.JS ------------------------------------------------------------------- //

	// Canvas
	const canvas = document.querySelector("canvas.webgl");

	// Scene
	const scene = new THREE.Scene();

	/**
	 * Textures
	 */
	const textureLoader = new THREE.TextureLoader();
	const particleTexture = textureLoader.load("../textures/particles/8.png");

	// Group
	const galaxy = new THREE.Group();
	scene.add(galaxy);

	/* Particles */

	// Geometry
	const particlesGeometry = new THREE.BufferGeometry();
	const particlesGeometry2 = new THREE.BufferGeometry();
	const particlesGeometry3 = new THREE.BufferGeometry();
	const particlesGeometry4 = new THREE.BufferGeometry();
	const count = 250;
	const count2 = 600;

	const positions = new Float32Array(count * 3);
	//const colors = new Float32Array(count * 3);

	const positions2 = new Float32Array(count * 3);
	const positions3 = new Float32Array(count * 3);
	const positions4 = new Float32Array(count2 * 3);

	for (let i = 0; i < count * 3; i++) {
		positions[i] = (Math.random() - 0.5) * 10;
		//colors[i] = Math.random();

		positions2[i] = (Math.random() - 0.5) * 10;
		positions3[i] = (Math.random() - 0.5) * 10;
	}

	for (let i = 0; i < count2 * 3; i++) {
		positions4[i] = (Math.random() - 0.5) * 10;
	}

	particlesGeometry.setAttribute(
		"position",
		new THREE.BufferAttribute(positions, 3)
	);

	particlesGeometry2.setAttribute(
		"position",
		new THREE.BufferAttribute(positions2, 3)
	);

	particlesGeometry3.setAttribute(
		"position",
		new THREE.BufferAttribute(positions3, 3)
	);

	particlesGeometry4.setAttribute(
		"position",
		new THREE.BufferAttribute(positions4, 3)
	);

	//particlesGeometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));

	// Material
	const particlesMaterial = new THREE.PointsMaterial({
		size: 0.07,
		sizeAttenuation: true,
		color: "#f062a4",
		transparent: true,
		alphaMap: particleTexture,
		// alphaTest: 0.001,
		// depthTest: false,
		depthWrite: false,
		blending: THREE.AdditiveBlending,
		// vertexColors: true,
	});

	const particlesMaterial2 = new THREE.PointsMaterial({
		size: 0.07,
		sizeAttenuation: true,
		color: "#6252ea",
		transparent: true,
		alphaMap: particleTexture,
		depthWrite: false,
		blending: THREE.AdditiveBlending,
	});

	const particlesMaterial3 = new THREE.PointsMaterial({
		size: 0.07,
		sizeAttenuation: true,
		color: "#fbb54b",
		transparent: true,
		alphaMap: particleTexture,
		depthWrite: false,
		blending: THREE.AdditiveBlending,
	});

	const particlesMaterial4 = new THREE.PointsMaterial({
		size: 0.075,
		sizeAttenuation: true,
		color: "#ffffff",
		transparent: true,
		alphaMap: particleTexture,
		depthWrite: false,
		blending: THREE.AdditiveBlending,
	});

	// Points
	const particles = new THREE.Points(particlesGeometry, particlesMaterial);
	const particles2 = new THREE.Points(particlesGeometry2, particlesMaterial2);
	const particles3 = new THREE.Points(particlesGeometry3, particlesMaterial3);
	const particles4 = new THREE.Points(particlesGeometry4, particlesMaterial4);

	galaxy.add(particles);
	galaxy.add(particles2);
	galaxy.add(particles3);
	galaxy.add(particles4);

	/**
	 * Sizes
	 */
	const sizes = {
		width: window.innerWidth,
		height: window.innerHeight,
	};

	window.addEventListener("resize", () => {
		// Update sizes
		sizes.width = window.innerWidth;
		sizes.height = window.innerHeight;

		// Update camera
		camera.aspect = sizes.width / sizes.height;
		camera.updateProjectionMatrix();

		// Update renderer
		renderer.setSize(sizes.width, sizes.height);
		renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
	});

	/**
	 * Camera
	 */

	// Base camera
	const camera = new THREE.PerspectiveCamera(
		75,
		sizes.width / sizes.height,
		0.1,
		100
	);
	camera.position.x = 0;
	camera.position.y = 0;
	camera.position.z = 2.5;
	scene.add(camera);

	/**
	 * Renderer
	 */
	const renderer = new THREE.WebGLRenderer({
		canvas: canvas,
		// alpha: true,
	});
	renderer.setSize(sizes.width, sizes.height);
	renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

	/**
	 * Animate
	 */

	let mouseX = 0;
	let mouseY = 0;
	let targetX = 0;
	let targetY = 0;

	const windowHalfX = window.innerWidth / 2;
	const windowHalfY = window.innerHeight / 2;

	const onDocumentMouseMove = (event) => {
		mouseX = event.clientX - windowHalfX;
		mouseY = event.clientY - windowHalfY;
	};

	const updateSphere = (event) => {
		let calcOpacity =
			(document.body.clientHeight - window.innerHeight - window.scrollY) /
			(document.body.clientHeight - window.innerHeight);

		galaxy.position.z = window.scrollY * 0.001 * calcOpacity;
	};

	window.addEventListener("scroll", updateSphere);

	document.addEventListener("mousemove", onDocumentMouseMove);

	const clock = new THREE.Clock();

	const tick = () => {
		targetX = mouseX * 0.001;
		targetY = mouseY * 0.001;

		const elapsedTime = clock.getElapsedTime();

		galaxy.rotation.y = 0.6 * elapsedTime;
		galaxy.rotation.z = 0.05 * elapsedTime;

		galaxy.rotation.y += 0.9 * (targetX - galaxy.rotation.y);
		galaxy.rotation.x += 0.05 * (targetY - galaxy.rotation.x);

		// Render
		renderer.render(scene, camera);

		// Call tick again on the next frame
		window.requestAnimationFrame(tick);
	};

	tick();
}
